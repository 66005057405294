import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import moment from "moment";
import {
  getGoogleCalendarColor,
  deriveBuilder,
  getGoogleCalendarColorFromEvents,
  deriveBuilderFromEvent,
  getShortSummaryFromSrDescription,
} from "helpers/Service";
import { alpha, styled, useTheme } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import {
  Button,
  Grid,
  Tooltip,
  Typography,
} from "../../../../node_modules/@mui/material/index";
import DynamicDialog from "components/dialogs/Dialog";
import {
  CheckBoxOutlineBlank,
  Handyman,
  Hardware,
  PriorityHigh,
} from "../../../../node_modules/@mui/icons-material/index";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const dateTypeRegex = /(\d{1,4})([\/-])(\d{1,2})\2(\d{1,4})/;

const statusComparer = (v1, v2) => v1 - v2;

export default function EventSummaryTable(props) {
  const theme = useTheme();

  const { events, notesPopupFunction, dateRange } = props;

  const [pageSize, setPageSize] = useState(props.pageSize ? props.pageSize : 5);

  const renderDate = (params) => {
    return moment(params.formattedValue, "YYYY-MM-DDTHH:mm:ss").format(
      "MM-DD-YYYY",
    );
  };
  const renderIcons = (params) => {
    let futureTechVisit = null;
    if (
      params.row.home?.axiosCalendarEvents &&
      params.row.home?.axiosCalendarEvents.length > 0
    ) {
      futureTechVisit = params.row.home?.axiosCalendarEvents.filter(
        (x) => !moment(x.closingTime, "YYYY-MM-DDTHH:mm:ss").isBefore(),
      );
    }
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {" "}
        <Grid xs={6} item>
          {futureTechVisit && futureTechVisit[0] && (
            <Tooltip
              title={
                <div style={{ whiteSpace: "pre-line" }}>{`${
                  futureTechVisit[0].calendarNotes
                } - ${moment(
                  futureTechVisit[0].closingTime,
                  "YYYY-MM-DDTHH:mm:ss",
                ).format("MM-DD-YYYY")}`}</div>
              }
            >
              <Handyman style={{}} />
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={6}>
          {params.row.home?.alertMessage && (
            <Tooltip
              title={
                <div style={{ whiteSpace: "pre-line" }}>
                  {params.row.home?.alertMessage}
                </div>
              }
            >
              <PriorityHigh
                style={{
                  color: "red",
                }}
              />
            </Tooltip>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderNoteColumn = (params) => {
    const { id } = params;

    return (
      <Box sx={{ display: "flex" }}>
        <Typography
          variant="subtitle"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            height: "3.6em",
            WebkitBoxOrient: "vertical",
          }}
        >
          {params.formattedValue}
        </Typography>
        {notesPopupFunction && (
          <Button
            sx={{
              fontSize: "1em",
            }}
            onClick={() => {
              notesPopupFunction(params.row.event.calendarNotes);
            }}
          >
            {" "}
            more...
          </Button>
        )}
      </Box>
    );
  };

  const columns = [
    { field: "date", headerName: "Date", renderCell: renderDate, width: 150 },
    {
      field: "address",
      headerName: "Address",
      width: 200,
    },
    {
      field: "visit",
      headerName: "",
      width: 50,
      renderCell: (params) => renderIcons(params),
    },
    {
      field: "status",
      valueGetter: (value, row) => {
        return value?.row?.event?.calendarColorId ?? 0;
      },
      sortComparator: statusComparer,
      headerName: "Status",
      renderCell: (params) =>
        getGoogleCalendarColorFromEvents(params.row.event),
    },
    { field: "builder", headerName: "Builder" },
    { field: "tech", headerName: "Technician" },
    { field: "updated", headerName: "Updated", width: 150 },
    {
      field: "note",
      headerName: "Last Activity",
      width: 500,
      height: "auto",
      renderCell: (params) => renderNoteColumn(params),
    },
  ];

  let rows = events?.map((event, key) => {
    const eventDate = moment(event.closingTime, "YYYY-MM-DDTHH:mm:ss");
    const updateDate = moment(event.updated, "YYYY-MM-DDTHH:mm:ss");
    if (
      dateRange &&
      !eventDate.isBetween(
        moment(dateRange[0], "YYYY-MM-DDTHH:mm:ss"),
        moment(dateRange[1], "YYYY-MM-DDTHH:mm:ss"),
      )
    ) {
      return;
    }

    let { home } = event;

    if (home) {
      //event is associated with a home
      let lastNote;

      let deriveBldr = deriveBuilder(event.home);

      if (event?.calendarNotes) {
        lastNote = getShortSummaryFromSrDescription(event.calendarNotes);
      }

      if (event) {
        if (!lastNote) {
          let notesByLine = event?.calendarNotes;
          if (notesByLine && notesByLine.includes("\n")) {
            notesByLine = notesByLine.split("\n");
          }
          if (notesByLine && notesByLine.includes("<br>")) {
            notesByLine = notesByLine.split("<br>");
          }
        }
        let tech = null;
        if (home) {
          tech = home?.bimRecord?.bimChecklists[1]?.created_by
            ? home?.bimRecord?.bimChecklists[1]?.created_by
            : home?.bimRecord?.bimChecklists[0]?.created_by;
        }

        if (!tech && home) {
          console.log("no tech", home.bimRecord?.bimChecklists);
        }
        // "MM-DD-YYYY"
        return {
          date: eventDate,
          address: event.address,
          status: getGoogleCalendarColorFromEvents(event),
          builder: deriveBldr,
          tech: tech,
          note: lastNote ? lastNote : "See full notes",
          id: key,
          home: home,
          event: event,
          updated: updateDate,
        };
      } else {
        return;
      }
    } else {
      //event is a pure calendar event, like a PTO or a meeting

      return {
        date: eventDate,
        address: event.address,
        status: getGoogleCalendarColorFromEvents(event),
        builder: "",
        tech: "",
        note: event.summary,
        id: key,
        home: null,
        event: event,
        updated: updateDate,
      };
    }
  });
  rows = rows.filter(Boolean);

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: 400,

        height: props.height
          ? props.height
          : Math.max(150 + Math.min(rows.length, pageSize) * 44.19, 400),
      }}
    >
      <StripedDataGrid
        getRowHeight={() => "auto"}
        columns={columns}
        rows={rows}
        rowThreshold={0}
        onRowClick={(params, event) => {
          if (event.target.type === "button") return; //dont trigger the whole row popup if we click a nested button

          if (params.row) {
            props.showHomeDetails(params.row);
          }
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20, 50]}
        pagination
        initialState={{
          sorting: {
            sortModel: [{ field: "date", sort: "asc" }],
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        sx={{
          [`& .${gridClasses.row}`]: {
            "&:hover, &.Mui-hovered": {
              backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
              "@media (hover: none)": {
                backgroundColor: "transparent",
              },
            },
          },
          fontWeight: "500",
          textTransform: "capitalize",
        }}
      />
    </Box>
  );
}

EventSummaryTable.propTypes = {
  events: PropTypes.array,
  showHomeDetails: PropTypes.func,
};
